import React, { useEffect } from 'react';
import {
  Box,
  Chip,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Check, Close, DocumentScanner, Fullscreen } from '@mui/icons-material';
import FullScreenView from '../components/loading/FullScreenView';
import { loadingActions } from '../store/reducers/loading-reducer';
import { orderDetailsLoading as Data } from '../contants/demo-data';

const Loading = () => {
  const [selectedBay, setSelectedBay] = React.useState();
  const [selectedOperation, setSelectedOperatons] = React.useState();
  const [mins, setMins] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const [hours, setHours] = React.useState(0);

  const [showFullScreen, setShowFullScreen] = React.useState(false);

  React.useEffect(() => {
    const interval = setInterval(() => setHours((t) => t + 1), 1000 * 60 * 60);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => setMins((t) => (t < 60 ? t + 1 : 0)), 1000 * 60);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => setSeconds((t) => (t < 60 ? t + 1 : 0)), 1000);
    return () => clearInterval(interval);
  }, []);

  const selectedWarehouse = useSelector((state) => state.warehouse.selectedWarehouse);
  const loadingOperations = useSelector((state) => state.loadingOperation.loadingOperations);

  const selectFirstBayOnLoad = () => {
    const firstBay = selectedWarehouse.bays.find((item) => item.status === 'UNLOADING');
    setSelectedBay(firstBay);
  };

  const findOperationForSelectecBay = () => {
    const operation = loadingOperations.find((item) => item.bayName === selectedBay?.name);
    setSelectedOperatons(operation);
  };

  React.useEffect(() => findOperationForSelectecBay(), [selectedBay]);
  React.useEffect(() => selectFirstBayOnLoad(), []);

  const isBaySelected = (bay) => selectedBay?.name === bay?.name;
  const handleOpen = () => {
    const doc = window.document;
    const docEl = doc.documentElement;

    const requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    }
    setShowFullScreen(true);
  };
  const handleClose = () => {
    const doc = window.document;

    const cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    cancelFullScreen.call(doc);
    setShowFullScreen(false);
  };

  const dispatch = useDispatch();

  const handleScan = (sku) => {
    dispatch(loadingActions.scanItem({ sku, orderNumber: selectedOperation.orderNumber }));
    setSelectedOperatons((op) => {
      return {
        ...op,
        products: op.products.map((pr) => (pr.sku === sku ? { ...pr, isScanned: true } : pr)),
      };
    });
  };

  const reset = () => {
    setSelectedOperatons(Data.find((item) => item.orderNumber === selectedOperation.orderNumber));
    dispatch(loadingActions.resetScan());
  };

  const rotateBay = () => {
    const randIndex = Math.floor(Math.random() * selectedWarehouse?.bays?.length) + 0;
    const bay = selectedWarehouse.bays[randIndex || 0];
    setSelectedBay(bay);
  };

  useEffect(() => {
    const interval = setInterval(() => rotateBay(), 1000 * 60 * 10);

    return () => clearInterval(interval);
  }, []);

  return (
    <Stack spacing={2} width="100%" height="100%">
      <FullScreenView open={showFullScreen} onClose={handleClose} />
      <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center">
        {selectedWarehouse.bays.map((item) => (
          <Stack
            key={item.id}
            px={2}
            py={2}
            bgcolor={isBaySelected(item) ? '#1E3163' : '#171820'}
            onClick={() => setSelectedBay(item)}
            sx={{ cursor: 'pointer' }}
            mr={1}
            mb={1}
            borderRadius={2}
            spacing={1}
            alignItems="center"
            justifyContent="center">
            <Typography fontWeight={700} variant="h5">
              {item.name}
            </Typography>
            <Chip
              size="small"
              label={item.status}
              color={
                item.status === 'LOADING'
                  ? 'success'
                  : item.status === 'UNLOADING'
                  ? 'info'
                  : 'warning'
              }
            />
          </Stack>
        ))}
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography fontSize="1.1rem" fontWeight={700}>
          Showing Result For
        </Typography>
        {selectedBay ? (
          <Chip label={selectedBay.name} size="small" />
        ) : (
          <Chip label="Select a bay to view details" size="small" />
        )}
      </Stack>
      <Stack direction="row" bgcolor="#1e3163" width="max-content">
        <IconButton title="Launch Full Screen" onClick={handleOpen}>
          <Fullscreen />
        </IconButton>
      </Stack>
      {selectedOperation ? (
        <Stack spacing={2}>
          <Stack direction="row" flexWrap="wrap">
            <Stack px={2} bgcolor="#ECB365" py={2} mr={1} mb={1} color="#171820" borderRadius={2}>
              <Typography variant="h5" fontWeight={700}>
                {selectedOperation?.orderType}-{selectedOperation.orderNumber}
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Chip
                  label={selectedBay.status}
                  size="small"
                  color={
                    selectedBay.status === 'LOADING'
                      ? 'success'
                      : selectedBay.status === 'UNLOADING'
                      ? 'info'
                      : 'warning'
                  }
                />
                <Typography variant="body1" fontWeight={700}>
                  {selectedOperation.bayName}
                </Typography>
              </Stack>
            </Stack>
            <Stack px={2} bgcolor="#1E3163" py={2} mr={1} mb={1} borderRadius={2} onClick={reset}>
              <Typography variant="body1" fontWeight={700}>
                Truck No.
              </Typography>
              <Typography variant="h4" fontWeight={700}>
                {selectedOperation.truckNumber}
              </Typography>
            </Stack>
            <Stack px={2} bgcolor="#1E3163" py={2} mr={1} mb={1} borderRadius={2}>
              <Typography variant="body1" fontWeight={700}>
                Source
              </Typography>
              <Typography variant="h4" fontWeight={700}>
                {selectedOperation.source}
              </Typography>
            </Stack>
            <Stack px={2} bgcolor="#1E3163" py={2} mr={1} mb={1} borderRadius={2}>
              <Typography variant="body1" fontWeight={700}>
                Destination
              </Typography>
              <Typography variant="h4" fontWeight={700}>
                {selectedOperation.destination}
              </Typography>
            </Stack>
            <Stack px={2} bgcolor="#1E3163" py={2} mr={1} mb={1} borderRadius={2} width={200}>
              <Typography variant="body1" fontWeight={700}>
                Completion Time
              </Typography>
              <Typography variant="h4" fontWeight={700}>
                {hours >= 10 ? hours : `0${hours}`}:{mins >= 10 ? mins : `0${mins}`}:
                {seconds >= 10 ? seconds : `0${seconds}`}
              </Typography>
            </Stack>
          </Stack>
          <Box width="100%" bgcolor="#171820" borderRadius={3}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SKU</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Order Quantity</TableCell>
                  <TableCell>Order Delivered</TableCell>
                  <TableCell>Order Received</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedOperation?.products
                  ?.filter((item) => item.isScanned)
                  ?.map((item) => (
                    <TableRow key={item.sku}>
                      <TableCell>{item.sku}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.orderQuantity}</TableCell>
                      <TableCell>{item.deliveredQuantity}</TableCell>
                      <TableCell>{item.receivedQuantity}</TableCell>
                    </TableRow>
                  ))}
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="right">Total</TableCell>
                  <TableCell>
                    {selectedOperation?.products
                      ?.filter((item) => item.isScanned)
                      ?.reduce((pr, cr) => pr + cr.orderQuantity, 0)}
                  </TableCell>
                  <TableCell>
                    {selectedOperation?.products
                      ?.filter((item) => item.isScanned)
                      ?.reduce((pr, cr) => pr + cr.deliveredQuantity, 0)}
                  </TableCell>
                  <TableCell>
                    {selectedOperation?.products
                      ?.filter((item) => item.isScanned)
                      ?.reduce((pr, cr) => pr + cr.receivedQuantity, 0)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Typography fontSize="1.1rem" fontWeight={700}>
            Yet To Scan
          </Typography>
          <Box width="100%" bgcolor="#171820" borderRadius={3}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Bar Code</TableCell>
                  <TableCell>Pack Code</TableCell>
                  <TableCell>Production Date</TableCell>
                  {selectedBay?.status === 'UNLOADING' && <TableCell>Scan</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedOperation?.products
                  ?.filter((item) => !item.isScanned)
                  ?.map((item, index) => (
                    <TableRow key={item.sku}>
                      <TableCell>
                        {item.isScanned ? <Check /> : <Close sx={{ fill: '#EB4747' }} />}
                      </TableCell>
                      <TableCell>{item.sku}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>
                        {selectedBay?.status === 'LOADING'
                          ? '-'
                          : `44177560522G${30100731 + index}`}
                      </TableCell>
                      <TableCell>
                        {selectedBay?.status === 'LOADING' ? '-' : `29052G${93 + index * 3}`}
                      </TableCell>
                      <TableCell>
                        {selectedBay?.status === 'LOADING'
                          ? '-'
                          : `2022-01-${index > 0 ? index * 9 : 12}`}
                      </TableCell>
                      {selectedBay?.status === 'UNLOADING' && (
                        <TableCell>
                          <IconButton title="Scan" onClick={() => handleScan(item.sku)}>
                            <DocumentScanner />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </Stack>
      ) : (
        <Stack width="100%" height="50vh" spacing={2} alignItems="center" justifyContent="center">
          <Box component="img" src="/assets/images/box.png" height={150} />
          <Typography variant="h3" fontWeight={600}>
            No Operation
          </Typography>
          <Typography fontSize="1.1rem" letterSpacing={1}>
            Loading Bay <strong>&ldquo;{selectedBay?.name}&ldquo;</strong> is currently sitting idle
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default Loading;
