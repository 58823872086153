import React from 'react';
import { Box } from '@mui/material';

export const Navbar = () => {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Box
          component="img"
          src="/assets/images/logo.png"
          height="60px"
          bgcolor="white"
          px={1}
          py={0.5}
          boxSizing="border-box"
          borderRadius={1}
        />
      </Box>
      <Box sx={{ flexGrow: 0 }}></Box>
    </>
  );
};
