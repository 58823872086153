import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuth: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signin: (state) => {
      state.isAuth = true;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
