import { Stack, Typography, IconButton, Box, Chip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Fullscreen, FilterList } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import FullScreenOrderModal from '../components/orders/FullScreenOrderModal';
import dayjs from 'dayjs';

const Orders = () => {
  const selectedWareHouse = useSelector((state) => state.warehouse.selectedWarehouse);
  const operations = useSelector((state) => state.operation.operations)
    .filter((item) => item.warehouseId === selectedWareHouse.warehouseId)
    .sort((item1, item2) => {
      if (item1.status === 'LOADING' || item2.status === 'UNLOADING') {
        return 0;
      } else {
        return 1;
      }
    });

  const [showFullScreenModal, setShowFullScreenModal] = useState(false);
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  const stats = [
    {
      label: 'In Route',
      count: operations.filter((item) => item.orderStatus === 'IN TRANSIT').length,
    },
    {
      label: 'Loading',
      count: operations.filter((item) => item.orderStatus === 'LOADING').length,
    },
    {
      label: 'Unloading',
      count: operations.filter((item) => item.orderStatus === 'UNLOADING').length,
    },
  ];

  const getStatColor = (label) => {
    const colors = {
      Route: '#FFCB42',
      Loading: '#90B77D',
      Unloading: '#0096FF',
    };

    return colors[label];
  };

  const getStatusColor = (status) => {
    const colors = {
      'IN TRANSIT': 'warning',
      'WAITING FOR UNLOAD': 'info',
      COMPLETED: 'success',
      LOADING: 'secondary',
      UNLOADING: 'primary',
    };
    return colors[status];
  };

  const columns = [
    {
      field: 'orderType',
      headerName: 'Order',
      renderCell: (params) => `${params.row.orderType}-${params.row.orderNumber}`,
      width: 150,
    },
    {
      field: 'orderStatus',
      headerName: 'Status',
      width: 200,
      renderCell: (params) => (
        <Chip label={params.value} size="small" color={getStatusColor(params.value)} />
      ),
    },
    {
      field: 'source',
      headerName: 'Source',
      width: 200,
    },
    {
      field: 'destination',
      headerName: 'Destination',
      width: 200,
    },
    {
      field: 'truckNumber',
      headerName: 'Truck',
      width: 200,
    },
    {
      field: 'truckDriver',
      headerName: 'Driver',
      width: 200,
    },
    {
      field: 'contactNumber',
      headerName: 'Contact',
      width: 150,
    },
    {
      field: 'truckLocation',
      headerName: 'Location',
      width: 150,
    },
    {
      field: 'eta',
      headerName: 'ETA',
      width: 200,
    },
    {
      field: 'etd',
      headerName: 'ETD',
      width: 200,
    },
  ];

  const handleOpen = () => {
    const doc = window.document;
    const docEl = doc.documentElement;

    const requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    }
    setShowFullScreenModal(true);
  };
  const handleClose = () => {
    const doc = window.document;

    const cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    cancelFullScreen.call(doc);
    setShowFullScreenModal(false);
  };

  return (
    <Stack spacing={2} width="100%" height="auto" minHeight="88vh">
      <FullScreenOrderModal
        onClose={handleClose}
        show={showFullScreenModal}
        operations={operations}
        stats={stats}
      />
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" flexWrap="wrap">
          <Stack
            bgcolor="#1E3163"
            borderLeft={6}
            borderLeftColor="#0096FF"
            mr={2}
            mb={2}
            boxSizing="border-box"
            px={2}
            py={2}>
            <Typography fontSize="1.1rem" fontWeight={400}>
              Warehouse
            </Typography>
            <Typography fontSize="2rem" fontWeight={800}>
              {selectedWareHouse.name}
            </Typography>
          </Stack>
          {stats.map((stat) => (
            <Stack
              bgcolor="#1E3163"
              borderLeft={6}
              borderLeftColor={getStatColor(stat.label)}
              key={stat.label}
              mr={2}
              mb={2}
              boxSizing="border-box"
              px={2}
              py={2}>
              <Typography fontSize="1.1rem" fontWeight={400}>
                {stat.label}
              </Typography>
              <Typography fontSize="3rem" fontWeight={800}>
                {stat.count}
              </Typography>
            </Stack>
          ))}
        </Stack>
        <Stack
          bgcolor="#1E3163"
          borderLeft={6}
          borderLeftColor="#EB4747"
          boxSizing="border-box"
          mb={2}
          px={2}
          py={2}
          width={250}>
          <Typography fontSize="1.1rem" fontWeight={400}>
            {dayjs(new Date()).format('DD MMM, YYYY')}
          </Typography>
          <Typography fontSize="2rem" fontWeight={800}>
            {dayjs(time).format('hh:mm:ss A')}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" bgcolor="#1e3163" width="max-content">
        <IconButton title="Launch Full Screen" onClick={handleOpen}>
          <Fullscreen />
        </IconButton>
        <IconButton title="Filter">
          <FilterList />
        </IconButton>
      </Stack>
      <Box
        width="100%"
        height="68vh"
        sx={{
          '& .super-app-theme--warning': {
            bgcolor: () => '#EB4747',
          },
          '& .super-app-theme--success': {
            bgcolor: () => '#FBB454',
          },
        }}>
        <DataGrid
          columns={columns}
          rows={operations}
          getRowId={(row) => row.orderNumber}
          getRowClassName={(params) =>
            `super-app-theme--${
              params.row.orderStatus === 'IN TRANSIT'
                ? 'warning'
                : params.row.orderStatus === 'LOADING'
                ? 'success'
                : 'default'
            }`
          }
          sx={{
            borderColor: '#171820',
            '& .MuiDataGrid-cell': {
              borderColor: '#171820',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderColor: '#171820',
            },
          }}
        />
      </Box>
    </Stack>
  );
};

export default Orders;
