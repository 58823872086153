import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      paper: '#004f9f',
      default: '#004f9f',
    },
  },
  typography: {
    // eslint-disable-next-line quotes
    fontFamily: "'Poppins', sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontWeight: 'bold',
          backgroundColor: '#ECB365',
          '&:hover': {
            backgroundColor: '#FEC260',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundImage: 'none',
        },
        root: {
          backgroundImage: 'none',
        },
      },
    },
  },
});

export default theme;
