import { combineReducers } from 'redux';
import authReducer from './auth-reducer';
import loadingReducer from './loading-reducer';
import operationReducer from './operation-reducer';
import unloadingReducer from './unloading-reducer';
import warehouseReducer from './warehouse-reducer';

const reducers = combineReducers({
  auth: authReducer,
  warehouse: warehouseReducer,
  operation: operationReducer,
  loadingOperation: loadingReducer,
  unloadingOperation: unloadingReducer,
});

export default reducers;
