import React from 'react';
import {
  Box,
  Chip,
  Dialog,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Check, Close, DocumentScanner } from '@mui/icons-material';
import AlertAnim from '../../anim/success.json';
import Lottie from 'react-lottie';
import { loadingActions } from '../../store/reducers/loading-reducer';
import { orderDetailsLoading as Data } from '../../contants/demo-data';

const FullScreenView = ({ open, onClose }) => {
  const [selectedBay, setSelectedBay] = React.useState();
  const [selectedOperation, setSelectedOperatons] = React.useState();
  const [mins, setMins] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const [hours, setHours] = React.useState(0);
  const [showAlertModal, setShowAlertModal] = React.useState(false);

  React.useEffect(() => {
    const interval = setInterval(() => setHours((t) => t + 1), 1000 * 60 * 60);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => setMins((t) => (t < 60 ? t + 1 : 0)), 1000 * 60);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => setSeconds((t) => (t < 60 ? t + 1 : 0)), 1000);
    return () => clearInterval(interval);
  }, []);

  const selectedWarehouse = useSelector((state) => state.warehouse.selectedWarehouse);
  const loadingOperations = useSelector((state) => state.loadingOperation.loadingOperations);

  const selectFirstBayOnLoad = () => {
    const firstBay = selectedWarehouse.bays.find((item, index) => index === 0);
    setSelectedBay(firstBay);
  };

  const findOperationForSelectecBay = () => {
    const operation = loadingOperations.find((item) => item.bayName === selectedBay?.name);
    setSelectedOperatons(operation);
  };

  React.useEffect(() => findOperationForSelectecBay(), [selectedBay]);
  React.useEffect(() => selectFirstBayOnLoad(), []);

  const isBaySelected = (bay) => selectedBay?.name === bay?.name;

  React.useEffect(() => {
    const interval = setInterval(() => setShowAlertModal(true), 1000 * 60 * 5);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => setShowAlertModal(false), 1000 * 60 * 10);
    return () => clearInterval(interval);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AlertAnim,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const dispatch = useDispatch();

  const handleScan = (sku) => {
    dispatch(loadingActions.scanItem({ sku, orderNumber: selectedOperation.orderNumber }));
    setSelectedOperatons((op) => {
      return {
        ...op,
        products: op.products.map((pr) => (pr.sku === sku ? { ...pr, isScanned: true } : pr)),
      };
    });
  };

  const reset = () => {
    setSelectedOperatons(Data.find((item) => item.orderNumber === selectedOperation.orderNumber));
    dispatch(loadingActions.resetScan());
  };

  return (
    <>
      <Dialog
        open={showAlertModal}
        fullWidth
        maxWidth="lg"
        onClose={() => setShowAlertModal(false)}>
        <Stack px={2} py={2} bgcolor="#90B77D">
          <Lottie options={defaultOptions} width={250} height={250} />
          <Stack alignItems="center" justifyContent="center" spacing={2} direction="row">
            <Stack flex={1} alignItems="center">
              <Typography fontWeight={800} variant="h3">
                LOADING COMPLETED
              </Typography>
              <Typography fontWeight={700} variant="h1">
                1 hour
              </Typography>
            </Stack>
            <Stack flex={1} alignItems="center">
              <Typography fontWeight={700} variant="h4">
                STO-470311234
              </Typography>
              <Typography fontWeight={700} variant="h4">
                DHM TA 17-2134
              </Typography>
              <Typography fontWeight={700} variant="h4">
                Phonix FG WH
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>
      <Dialog fullScreen open={open} onClose={onClose}>
        <Stack spacing={2} width="100%" height="100%" px={2} py={2}>
          <Stack
            direction="row"
            alignItems="flex-start"
            width="100%"
            justifyContent="space-between">
            <Typography variant="h3" fontWeight={600}>
              {selectedBay?.name}
            </Typography>
            <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center">
              {selectedWarehouse.bays.map((item) => (
                <Stack
                  key={item.id}
                  px={2}
                  py={2}
                  bgcolor={isBaySelected(item) ? '#1E3163' : '#171820'}
                  onClick={() => setSelectedBay(item)}
                  sx={{ cursor: 'pointer' }}
                  mr={1}
                  mb={1}
                  borderRadius={2}
                  spacing={1}
                  alignItems="center"
                  justifyContent="center">
                  <Typography fontWeight={700} variant="h5">
                    {item.name}
                  </Typography>
                </Stack>
              ))}
            </Stack>
            <IconButton sx={{ bgcolor: '#1E3163' }} onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>

          {selectedOperation ? (
            <Stack spacing={2}>
              <Stack direction="row" flexWrap="wrap">
                <Stack
                  px={2}
                  bgcolor="#ECB365"
                  py={2}
                  mr={1}
                  mb={1}
                  color="#171820"
                  borderRadius={2}
                  onClick={() => setShowAlertModal(true)}>
                  <Typography variant="h5" fontWeight={700}>
                    {selectedOperation?.orderType}-{selectedOperation.orderNumber}
                  </Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Chip
                      label={selectedBay.status}
                      size="small"
                      color={
                        selectedBay.status === 'LOADING'
                          ? 'success'
                          : selectedBay.status === 'UNLOADING'
                          ? 'info'
                          : 'warning'
                      }
                    />
                    <Typography variant="body1" fontWeight={700}>
                      {selectedOperation.bayName}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  px={2}
                  bgcolor="#1E3163"
                  py={2}
                  mr={1}
                  mb={1}
                  borderRadius={2}
                  onClick={reset}>
                  <Typography variant="body1" fontWeight={700}>
                    Truck No.
                  </Typography>
                  <Typography variant="h4" fontWeight={700}>
                    {selectedOperation.truckNumber}
                  </Typography>
                </Stack>
                <Stack px={2} bgcolor="#1E3163" py={2} mr={1} mb={1} borderRadius={2}>
                  <Typography variant="body1" fontWeight={700}>
                    Source
                  </Typography>
                  <Typography variant="h4" fontWeight={700}>
                    {selectedOperation.source}
                  </Typography>
                </Stack>
                <Stack px={2} bgcolor="#1E3163" py={2} mr={1} mb={1} borderRadius={2}>
                  <Typography variant="body1" fontWeight={700}>
                    Destination
                  </Typography>
                  <Typography variant="h4" fontWeight={700}>
                    {selectedOperation.destination}
                  </Typography>
                </Stack>
                <Stack px={2} bgcolor="#1E3163" py={2} mr={1} mb={1} borderRadius={2} width={200}>
                  <Typography variant="body1" fontWeight={700}>
                    Completion Time
                  </Typography>
                  <Typography variant="h4" fontWeight={700}>
                    {hours >= 10 ? hours : `0${hours}`}:{mins >= 10 ? mins : `0${mins}`}:
                    {seconds >= 10 ? seconds : `0${seconds}`}
                  </Typography>
                </Stack>
              </Stack>
              <Box width="100%" bgcolor="#171820" borderRadius={3}>
                <Table sx={{ fontSize: 50 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: 20, fontWeight: 700 }}>SKU</TableCell>
                      <TableCell sx={{ fontSize: 20, fontWeight: 700 }}>Name</TableCell>
                      <TableCell sx={{ fontSize: 20, fontWeight: 700 }} align="center">
                        Order Quantity
                      </TableCell>
                      <TableCell sx={{ fontSize: 20, fontWeight: 700 }} align="center">
                        Order Delivered
                      </TableCell>
                      <TableCell sx={{ fontSize: 20, fontWeight: 700 }} align="center">
                        Order Received
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedOperation?.products
                      ?.filter((item) => item.isScanned)
                      ?.map((item) => (
                        <TableRow key={item.sku}>
                          <TableCell sx={{ fontSize: 20, fontWeight: 400 }}>{item.sku}</TableCell>
                          <TableCell sx={{ fontSize: 20, fontWeight: 400 }}>{item.name}</TableCell>
                          <TableCell sx={{ fontSize: 20, fontWeight: 400 }} align="center">
                            {item.orderQuantity}
                          </TableCell>
                          <TableCell sx={{ fontSize: 20, fontWeight: 400 }} align="center">
                            {item.deliveredQuantity}
                          </TableCell>
                          <TableCell sx={{ fontSize: 20, fontWeight: 400 }} align="center">
                            {item.receivedQuantity}
                          </TableCell>
                        </TableRow>
                      ))}
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="right" sx={{ fontSize: 20, fontWeight: 700 }}>
                        Total
                      </TableCell>
                      <TableCell sx={{ fontSize: 20, fontWeight: 700 }} align="center">
                        {selectedOperation?.products
                          ?.filter((item) => item.isScanned)
                          ?.reduce((pr, cr) => pr + cr.orderQuantity, 0)}
                      </TableCell>
                      <TableCell sx={{ fontSize: 20, fontWeight: 700 }} align="center">
                        {selectedOperation?.products
                          ?.filter((item) => item.isScanned)
                          ?.reduce((pr, cr) => pr + cr.deliveredQuantity, 0)}
                      </TableCell>
                      <TableCell sx={{ fontSize: 20, fontWeight: 700 }} align="center">
                        {isNaN(
                          selectedOperation?.products
                            ?.filter((item) => item.isScanned)
                            ?.reduce((pr, cr) => pr + cr.receivedQuantity, 0),
                        )
                          ? 0
                          : selectedOperation?.products
                              ?.filter((item) => item.isScanned)
                              ?.reduce((pr, cr) => pr + cr.receivedQuantity, 0)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Typography fontSize="1.1rem" fontWeight={700}>
                Yet To Scan
              </Typography>
              <Box width="100%" bgcolor="#171820" borderRadius={3}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: 20, fontWeight: 700 }}>Status</TableCell>
                      <TableCell sx={{ fontSize: 20, fontWeight: 700 }}>SKU</TableCell>
                      <TableCell sx={{ fontSize: 20, fontWeight: 700 }}>Description</TableCell>
                      <TableCell sx={{ fontSize: 20, fontWeight: 700 }}>Bar Code</TableCell>
                      <TableCell sx={{ fontSize: 20, fontWeight: 700 }}>Pack Code</TableCell>
                      <TableCell sx={{ fontSize: 20, fontWeight: 700 }}>Production Date</TableCell>
                      {selectedBay?.status === 'UNLOADING' && (
                        <TableCell sx={{ fontSize: 20, fontWeight: 700 }}>Scan</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedOperation?.products
                      ?.filter((item) => !item.isScanned)
                      ?.map((item, index) => (
                        <TableRow key={item.sku}>
                          <TableCell sx={{ fontSize: 20, fontWeight: 400 }}>
                            {item.isScanned ? <Check /> : <Close sx={{ fill: '#EB4747' }} />}
                          </TableCell>
                          <TableCell sx={{ fontSize: 20, fontWeight: 400 }}>{item.sku}</TableCell>
                          <TableCell sx={{ fontSize: 20, fontWeight: 400 }}>{item.name}</TableCell>
                          <TableCell sx={{ fontSize: 20, fontWeight: 400 }}>
                            44177560522G{30100731 + index}
                          </TableCell>
                          <TableCell sx={{ fontSize: 20, fontWeight: 400 }}>
                            29052G{93 + index * 3}
                          </TableCell>
                          <TableCell sx={{ fontSize: 20, fontWeight: 400 }}>
                            2022-01-{index > 0 ? index * 9 : 12}
                          </TableCell>
                          {selectedBay?.status === 'UNLOADING' && (
                            <TableCell>
                              <IconButton title="Scan" onClick={() => handleScan(item.sku)}>
                                <DocumentScanner />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Stack>
          ) : (
            <Stack
              width="100%"
              height="50vh"
              spacing={2}
              alignItems="center"
              justifyContent="center">
              <Box component="img" src="/assets/images/box.png" height={150} />
              <Typography variant="h3" fontWeight={600}>
                No Operation
              </Typography>
              <Typography fontSize="1.1rem" letterSpacing={1}>
                Loading Bay <strong>&ldquo;{selectedBay?.name}&ldquo;</strong> is currently sitting
                idle
              </Typography>
            </Stack>
          )}
        </Stack>
      </Dialog>
    </>
  );
};

export default FullScreenView;
