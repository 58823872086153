import React from 'react';
import { Autocomplete, Box, Chip, Stack, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { wareHouseActions } from '../store/reducers/warehouse-reducer';

const Dashboard = () => {
  const selectedWareHouse = useSelector((state) => state.warehouse.selectedWarehouse);
  const dispatch = useDispatch();
  const warehouses = useSelector((state) => state.warehouse.warehouses);

  const handleWareHouseSelection = (warehouse) =>
    dispatch(wareHouseActions.selectWareHouse(warehouse));
  console.log(selectedWareHouse);
  return (
    <Stack>
      <Box
        px={2}
        py={2}
        boxSizing="border-box"
        bgcolor="#1E3163"
        width="20%"
        borderRadius={2}
        mr={2}
        mb={2}>
        {selectedWareHouse ? (
          <Stack spacing={1}>
            <Autocomplete
              options={warehouses}
              value={selectedWareHouse}
              onChange={(event, newValue) => {
                handleWareHouseSelection(newValue);
              }}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getOptionLabel={(option) => `${option.name}`}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Selected Warehouse" />
              )}
            />
            <Stack direction="row" justifyContent="space-between">
              <Stack spacing={1}>
                <Typography>
                  Status <br />
                  <span style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                    {selectedWareHouse.status}
                  </span>
                </Typography>
                <Typography>
                  Supervisor <br />
                  <span style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                    {selectedWareHouse.supervisor}
                  </span>
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography>
                  Address <br />
                  <span style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                    {selectedWareHouse.location}
                  </span>
                </Typography>
                <Typography>
                  Number of bay <br />{' '}
                  <span style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                    {selectedWareHouse.bays.length}
                  </span>
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Stack spacing={1}>
            <Autocomplete
              options={warehouses}
              value={selectedWareHouse}
              onChange={(event, newValue) => {
                handleWareHouseSelection(newValue);
              }}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getOptionLabel={(option) => `${option.name}`}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Selected Warehouse" />
              )}
            />
            <Typography variant="h4" fontWeight={600}>
              No Warehouse Selected
            </Typography>
          </Stack>
        )}
      </Box>
      <Stack direction="row" spacing={1}>
        {selectedWareHouse &&
          selectedWareHouse.bays.map((item) => (
            <Stack
              px={2}
              py={2}
              boxSizing="border-box"
              bgcolor="#1E3163"
              width="20%"
              borderRadius={2}
              key={item.id}>
              <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                <Typography variant="h4" fontWeight={700}>
                  {item.name}
                </Typography>
                <Chip
                  label={item.status}
                  color={
                    item.status === 'LOADING'
                      ? 'success'
                      : item.status === 'UNLOADING'
                      ? 'info'
                      : 'warning'
                  }
                />
              </Stack>
              <Typography>STO-1254121</Typography>
              <Typography>Truck: DHM TA 15-2500</Typography>
              <Typography>
                {item.status === 'LOADING' ? 'Source: Phoneix FG WH' : 'Destination: Sylhet FG WH'}
              </Typography>
              <Typography>Total Items: 150</Typography>
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
};

export default Dashboard;
