import { Close } from '@mui/icons-material';
import { Box, Chip, Dialog, DialogContent, IconButton, Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Lottie from 'react-lottie';
import AlertAnim from '../../anim/alert.json';

const FullScreenOrderModal = ({ show, onClose, operations, stats }) => {
  const selectedWareHouse = useSelector((state) => state.warehouse.selectedWarehouse);
  const [time, setTime] = useState(new Date());
  const [showAlertModal, setShowAlertModal] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => setShowAlertModal(true), 1000 * 60 * 5);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => setShowAlertModal(false), 1000 * 60 * 10);
    return () => clearInterval(interval);
  }, []);

  const columns = [
    {
      field: 'orderType',
      headerName: 'Order',
      renderCell: (params) => `${params.row.orderType}-${params.row.orderNumber}`,
      flex: 1,
    },
    {
      field: 'orderStatus',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <Chip label={params.value} size="small" color={getStatusColor(params.value)} />
      ),
    },
    {
      field: 'source',
      headerName: 'Source',
      flex: 1,
    },
    {
      field: 'destination',
      headerName: 'Destination',
      flex: 1,
    },
    {
      field: 'truckNumber',
      headerName: 'Truck',
      flex: 1,
    },
    {
      field: 'truckDriver',
      headerName: 'Driver',
      flex: 1,
    },
    {
      field: 'contactNumber',
      headerName: 'Contact',
      flex: 1,
    },
    {
      field: 'truckLocation',
      headerName: 'Location',
      flex: 1,
    },
    {
      field: 'eta',
      headerName: 'ETA',
      flex: 1,
    },
    {
      field: 'etd',
      headerName: 'ETD',
      flex: 1,
    },
  ];

  const getStatColor = (label) => {
    const colors = {
      Route: '#FFCB42',
      Loading: '#90B77D',
      Unloading: '#0096FF',
    };

    return colors[label];
  };

  const getStatusColor = (status) => {
    const colors = {
      'IN TRANSIT': 'warning',
      'WAITING FOR UNLOAD': 'info',
      COMPLETED: 'success',
      LOADING: 'secondary',
      UNLOADING: 'primary',
    };
    return colors[status];
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AlertAnim,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <Dialog
        open={showAlertModal}
        fullWidth
        maxWidth="lg"
        onClose={() => setShowAlertModal(false)}>
        <Stack px={2} py={2} bgcolor="#EB4747">
          <Lottie options={defaultOptions} width={250} height={250} />
          <Stack alignItems="center" justifyContent="center" spacing={2} direction="row">
            <Stack flex={1} alignItems="center">
              <Typography fontWeight={800} variant="h3">
                ETA ALERT
              </Typography>
              <Typography fontWeight={700} variant="h1">
                15 mins
              </Typography>
            </Stack>
            <Stack flex={1} alignItems="center">
              <Typography fontWeight={700} variant="h4">
                STO-470311234
              </Typography>
              <Typography fontWeight={700} variant="h4">
                DHM TA 17-2134
              </Typography>
              <Typography fontWeight={700} variant="h4">
                Phonix FG WH
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>
      <Dialog open={show} onClose={onClose} fullScreen>
        <DialogContent>
          <Stack
            direction="row"
            alignItems="flex-start"
            width="100%"
            heght="100%"
            justifyContent="space-between">
            <Typography variant="h3" fontWeight={600}>
              Operation
            </Typography>
            <IconButton sx={{ bgcolor: '#1E3163' }} onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" flexWrap="wrap" mt={3}>
              <Stack
                bgcolor="#1E3163"
                borderLeft={6}
                borderLeftColor="#0096FF"
                boxSizing="border-box"
                mr={2}
                mb={2}
                px={2}
                py={2}
                onClick={() => setShowAlertModal(true)}>
                <Typography fontSize="1.1rem" fontWeight={400}>
                  Warehouse
                </Typography>
                <Typography fontSize="2rem" fontWeight={800}>
                  {selectedWareHouse.name}
                </Typography>
              </Stack>
              {stats.map((stat) => (
                <Stack
                  bgcolor="#1E3163"
                  borderLeft={6}
                  borderLeftColor={getStatColor(stat.label)}
                  key={stat.label}
                  boxSizing="border-box"
                  mr={2}
                  mb={2}
                  px={2}
                  py={2}>
                  <Typography fontSize="1.1rem" fontWeight={400}>
                    {stat.label}
                  </Typography>
                  <Typography fontSize="3rem" fontWeight={800}>
                    {stat.count}
                  </Typography>
                </Stack>
              ))}
            </Stack>
            <Stack
              mt={3}
              bgcolor="#1E3163"
              borderLeft={6}
              borderLeftColor="#EB4747"
              boxSizing="border-box"
              mb={2}
              px={2}
              py={2}
              width={250}>
              <Typography fontSize="1.1rem" fontWeight={400}>
                {dayjs(new Date()).format('DD MMM, YYYY')}
              </Typography>
              <Typography fontSize="2rem" fontWeight={800}>
                {dayjs(time).format('hh:mm:ss A')}
              </Typography>
            </Stack>
          </Stack>
          <Box
            width="100%"
            height="68vh"
            mt={3}
            sx={{
              '& .super-app-theme--warning': {
                bgcolor: () => '#EB4747',
              },
              '& .super-app-theme--success': {
                bgcolor: () => '#FBB454',
              },
            }}>
            <DataGrid
              components={{ Pagination: null }}
              columns={columns}
              rows={operations}
              getRowId={(row) => row.orderNumber}
              density="comfortable"
              sx={{
                fontSize: 18,
                borderColor: '#171820',
                '& .MuiDataGrid-cell': {
                  borderColor: '#171820',
                },
                '& .MuiDataGrid-columnHeaders': {
                  borderColor: '#171820',
                },
              }}
              getRowClassName={(params) =>
                `super-app-theme--${
                  params.row.orderStatus === 'IN TRANSIT'
                    ? 'warning'
                    : params.row.orderStatus === 'LOADING'
                    ? 'success'
                    : 'default'
                }`
              }
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FullScreenOrderModal;
