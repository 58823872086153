import { orderDetailsLoading } from '../../contants/demo-data';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loadingOperations: orderDetailsLoading,
};

const loadingSlice = createSlice({
  name: 'loadingOperations',
  initialState,
  reducers: {
    scanItem: (state, action) => {
      console.log(action.payload.orderNumber);
      state.loadingOperations = state.loadingOperations.map((item) => {
        if (item.orderNumber === action.payload.orderNumber) {
          return {
            ...item,
            products: item.products.map((pr) =>
              pr.sku === action.payload.sku ? { ...pr, isScanned: true } : pr,
            ),
          };
        }
        return item;
      });
    },

    resetScan: (state) => {
      state.loadingOperations = orderDetailsLoading;
    },
  },
});

export const loadingActions = loadingSlice.actions;
export default loadingSlice.reducer;
