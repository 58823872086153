import { warehouses } from '../../contants/demo-data';
import { createSlice } from '@reduxjs/toolkit';

const initState = {
  warehouses,
  selectedWarehouse: {
    name: 'Sylhet FG WH',
    warehouseId: 2,
    location: 'Sylhet',
    bays: [
      { id: 4, name: 'BAY 1', status: 'IDLE' },
      { id: 5, name: 'BAY 2', status: 'UNLOADING' },
      { id: 6, name: 'BAY 3', status: 'IDLE' },
      { id: 7, name: 'BAY 4', status: 'IDLE' },
      { id: 8, name: 'BAY 5', status: 'LOADING' },
    ],
    supervisor: 'Mr. Touhid Tushar',
    status: 'IN OPERATION',
  },
};

const wareHouseSlice = createSlice({
  initialState: initState,
  name: warehouses,
  reducers: {
    selectWareHouse: (state, action) => {
      state.selectedWarehouse = action.payload;
    },
  },
});

export const wareHouseActions = wareHouseSlice.actions;
export default wareHouseSlice.reducer;
