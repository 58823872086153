import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import Footer from '../components/global/Footer';
const PublicLayout = () => {
  return (
    <Box width="100%" height="auto" minHeight="100vh" bgcolor="#004f9f">
      <Outlet />
      <Footer />
    </Box>
  );
};

export default PublicLayout;
