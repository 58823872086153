export const warehouses = [
  {
    name: 'Phoneix FG WH',
    warehouseId: 1,
    location: 'Dhaka',
    bays: [
      { id: 1, name: 'BAY 1', status: 'LOADING' },
      { id: 2, name: 'BAY 2', status: 'UNLOADING' },
      { id: 3, name: 'BAY 3', status: 'IDLE' },
    ],
    supervisor: 'Mr. Zahid Hasan',
    status: 'IN OPERATION',
  },
  {
    name: 'Sylhet FG WH',
    warehouseId: 2,
    location: 'Sylhet',
    bays: [
      { id: 4, name: 'BAY 1', status: 'IDLE' },
      { id: 5, name: 'BAY 2', status: 'UNLOADING' },
      { id: 6, name: 'BAY 3', status: 'IDLE' },
      { id: 7, name: 'BAY 4', status: 'IDLE' },
      { id: 8, name: 'BAY 5', status: 'LOADING' },
    ],
    supervisor: 'Mr. Touhid Tushar',
    status: 'IN OPERATION',
  },
];

export const orders = [
  {
    warehouseId: 2,
    orderType: 'STO',
    orderNumber: '470184799',
    source: 'Phoneix FG WH',
    destination: 'Sylhet FG WG',
    truckNumber: 'DHM TA 16-1234',
    truckDriver: 'Mr. Joshim Ahmed',
    contactNumber: '+8801711355057',
    truckLocation: 'IN ROUTE',
    orderStatus: 'IN TRANSIT',
    eta: '2 hours 10 mins',
    etd: 'n/a',
  },
  {
    warehouseId: 2,
    orderType: 'STO',
    orderNumber: '470184834',
    source: 'Ashulia FG WH',
    destination: 'Sylhet FG WG',
    truckNumber: 'DHM TA 17-1634',
    truckDriver: 'Mr. Mehedi Hasan',
    contactNumber: '+8801711355044',
    truckLocation: 'PARKING AREA',
    orderStatus: 'WAITING FOR UNLOAD',
    eta: 'n/a',
    etd: 'n/a',
  },
  {
    warehouseId: 2,
    orderType: 'STO',
    orderNumber: '470311234',
    source: 'Phoneix FG WH',
    destination: 'Sylhet FG WH',
    truckNumber: 'DHM TA 17-2334',
    truckDriver: 'Mr. Joshim',
    contactNumber: '+8801711312764',
    truckLocation: 'n/a',
    orderStatus: 'COMPLETED',
    eta: 'n/a',
    etd: 'n/a',
  },
  {
    warehouseId: 2,
    orderType: 'SO',
    orderNumber: '470344834',
    source: 'Sylhet FG WH',
    destination: 'MR Brothers',
    truckNumber: 'DHM TA 17-1334',
    truckDriver: 'Mr. Rakib Hasan',
    contactNumber: '+8801711355344',
    truckLocation: 'BAY 5',
    orderStatus: 'LOADING',
    eta: 'n/a',
    etd: '45 mins',
  },
  {
    warehouseId: 2,
    orderType: 'STO',
    orderNumber: '470312334',
    source: 'Phoneix FG WH',
    destination: 'Sylhet FG WH',
    truckNumber: 'DHM TA 17-2134',
    truckDriver: 'Mr. Abrar Rahman',
    contactNumber: '+8801711312344',
    truckLocation: 'BAY 2',
    orderStatus: 'UNLOADING',
    eta: 'n/a',
    etd: 'n/a',
  },
  {
    warehouseId: 2,
    orderType: 'STO',
    orderNumber: '470312365',
    source: 'Phoneix FG WH',
    destination: 'Sylhet FG WH',
    truckNumber: 'DHM TA 17-2134',
    truckDriver: 'Mr. Abrar Rahman',
    contactNumber: '+8801711352344',
    truckLocation: 'BAY 2',
    orderStatus: 'UNLOADING',
    eta: 'n/a',
    etd: 'n/a',
  },
];

export const orderDetailsUnLoading = [
  {
    orderType: 'STO',
    orderNumber: '470312334',
    bayStatus: 'UNLOADING',
    bayName: 'BAY 2',
    truckNumber: 'DHM TA 17-2134',
    source: 'Phoneix FG WH',
    destination: 'Sylhet FG WH',
    products: [
      {
        sku: 10096858,
        name: 'DERB RED 10/100 KRE SQ BAN SPARKCB',
        orderQuantity: 300,
        deliveredQuantity: 200,
        ReceivedQuantity: 30,
        inventory: [
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30100731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
          },
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30103731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
          },
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30102731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
          },
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30106731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
          },
        ],
      },
      {
        sku: 10094973,
        name: 'DERB BLUE 10/100 KRE SQ BAN SPARK_CBC',
        orderQuantity: 80,
        deliveredQuantity: 30,
        ReceivedQuantity: 0,
        inventory: [
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30100731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
            isScanned: true,
          },
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30103731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
            isScanned: true,
          },
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30102731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
            isScanned: true,
          },
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30106731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
            isScanned: true,
          },
        ],
      },
      {
        sku: 10117977,
        name: 'JPYR SWITC 20/200 KRE SQ BAN GRIFFIN',
        orderQuantity: 50,
        deliveredQuantity: 40,
        ReceivedQuantity: 10,
        inventory: [
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30100731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
            isScanned: false,
          },
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30103731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
            isScanned: false,
          },
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30102731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
            isScanned: false,
          },
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30106731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
            isScanned: true,
          },
        ],
      },
    ],
  },
];

export const orderDetailsLoading = [
  {
    orderType: 'STO',
    orderNumber: '470312334',
    bayStatus: 'UNLOADING',
    bayName: 'BAY 2',
    truckNumber: 'DHM TA 17-2134',
    source: 'Phoneix FG WH',
    destination: 'Sylhet FG WH',
    products: [
      {
        sku: 10096858,
        name: 'DERB RED 10/100 KRE SQ BAN SPARKCB',
        orderQuantity: 300,
        deliveredQuantity: 200,
        receivedQuantity: 30,
        isScanned: false,
        inventory: [
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30100731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
          },
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30103731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
          },
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30102731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
          },
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30106731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
          },
        ],
      },
      {
        sku: 10094973,
        name: 'DERB BLUE 10/100 KRE SQ BAN SPARK_CBC',
        orderQuantity: 80,
        deliveredQuantity: 30,
        receivedQuantity: 0,
        isScanned: false,
        inventory: [
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30100731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
            isScanned: true,
          },
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30103731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
            isScanned: true,
          },
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30102731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
            isScanned: true,
          },
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30106731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
            isScanned: true,
          },
        ],
      },
      {
        sku: 10117977,
        name: 'JPYR SWITC 20/200 KRE SQ BAN GRIFFIN',
        orderQuantity: 50,
        deliveredQuantity: 40,
        receivedQuantity: 10,
        isScanned: true,
        inventory: [
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30100731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
            isScanned: false,
          },
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30103731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
            isScanned: false,
          },
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30102731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
            isScanned: false,
          },
          {
            skuDescription: 'STAR FFL 20/200 KRE SQ BAN DOM',
            barcode: '44177560522G30106731',
            packCode: '29052G93',
            productionDate: '2022-01-24',
            isScanned: true,
          },
        ],
      },
    ],
  },
  {
    orderType: 'SO',
    orderNumber: '470344834',
    bayStatus: 'LOADING',
    bayName: 'BAY 5',
    truckNumber: 'DHM TA 17-1334',
    source: 'Sylhet FG WH',
    destination: 'MR Brothers',
    products: [
      {
        sku: 10096858,
        name: 'DERB RED 10/100 KRE SQ BAN SPARKCB',
        orderQuantity: 300,
        deliveredQuantity: 200,
        receivedQuantity: 0,
        isScanned: false,
      },
      {
        sku: 10094973,
        name: 'DERB BLUE 10/100 KRE SQ BAN SPARK_CBC',
        orderQuantity: 80,
        deliveredQuantity: 30,
        receivedQuantity: 0,
        isScanned: false,
      },
      {
        sku: 10117977,
        name: 'JPYR SWITC 20/200 KRE SQ BAN GRIFFIN',
        orderQuantity: 50,
        deliveredQuantity: 40,
        receivedQuantity: 0,
        isScanned: true,
      },
    ],
  },
];
