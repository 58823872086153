import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material';
import { Dashboard, LocalShipping, Receipt } from '@mui/icons-material';

const Sidebar = ({ isOpen }) => {
  const menuItems = [
    {
      path: '/dashboard',
      label: 'Dashboard',
      Icon: Dashboard,
    },
    {
      path: '/operations',
      label: 'Operations',
      Icon: Receipt,
    },
    {
      path: '/loading',
      label: 'Loading/Unloading',
      Icon: LocalShipping,
    },
  ];

  const location = useLocation();
  return (
    <Stack justifyContent="space-between" height="100%" sx={{ bgcolor: '#171820' }}>
      <List sx={{ flexGrow: 1 }}>
        {menuItems.map((item) => {
          const { path, label, Icon } = item;
          const isSelected = location.pathname.endsWith(path);

          return (
            <Tooltip key={path} title={isOpen ? '' : label} placement="right">
              <ListItem disablePadding sx={{ display: 'block' }} component={Link} to={path}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Icon sx={{ fill: isSelected ? '#fff' : '#DDDDDD' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={label}
                    sx={{ opacity: open ? 1 : 0 }}
                    style={{ color: 'white' }}
                  />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Stack>
  );
};

export default Sidebar;
