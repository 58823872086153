import { createSlice } from '@reduxjs/toolkit';
import { orders } from '../../contants/demo-data';

const initialState = {
  operations: orders,
};

const operationSlice = createSlice({
  name: 'operation',
  initialState,
  reducers: {},
});

export const operationActions = operationSlice.actions;
export default operationSlice.reducer;
