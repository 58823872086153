import { orderDetailsUnLoading } from '../../contants/demo-data';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  unloadingOperations: orderDetailsUnLoading,
};

const unloadingSlice = createSlice({
  name: 'unloadingOperations',
  initialState,
  reducers: {},
});

export const unloadingActions = unloadingSlice.actions;
export default unloadingSlice.reducer;
