import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProtectedPage from './components/global/ProtectedPage';
import PrivateLayout from './layouts/PrivateLayout';
import PublicLayout from './layouts/PublicLayout';
import Dashboard from './pages/Dashboard';
import Loading from './pages/Loading';
import Orders from './pages/Orders';
import Signin from './pages/Signin';
import Unloading from './pages/Unloading';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateLayout />}>
          <Route
            path="/dashboard"
            element={
              <ProtectedPage>
                <Dashboard />
              </ProtectedPage>
            }
          />
        </Route>
        <Route element={<PrivateLayout />}>
          <Route
            path="/operations"
            element={
              <ProtectedPage>
                <Orders />
              </ProtectedPage>
            }
          />
        </Route>
        <Route element={<PrivateLayout />}>
          <Route
            path="/loading"
            element={
              <ProtectedPage>
                <Loading />
              </ProtectedPage>
            }
          />
        </Route>
        <Route element={<PrivateLayout />}>
          <Route
            path="/unloading"
            element={
              <ProtectedPage>
                <Unloading />
              </ProtectedPage>
            }
          />
        </Route>
        <Route element={<PublicLayout />}>
          <Route path="/" element={<Signin />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
