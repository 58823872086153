import React from 'react';
import { Box, Button, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../store/reducers/auth-reducer';
import { Navigate } from 'react-router-dom';

const Signin = () => {
  const [filedType, setFieldType] = React.useState('password');
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth);

  const handleSignIn = () => dispatch(authActions.signin());

  if (isAuth) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Stack
      width="100%"
      height="auto"
      minHeight="100vh"
      spacing={1.5}
      alignItems="center"
      justifyContent="center">
      <Box
        component="img"
        src="/assets/images/logo.png"
        height={120}
        bgcolor="#fff"
        borderRadius={2}
      />
      <Typography variant="h4" fontWeight={600}>
        Sign In
      </Typography>
      <Stack width="100%" maxWidth={310} spacing={2}>
        <TextField label="Email" placeholder="Please eneter your email" fullWidth />
        <TextField
          label="Password"
          placeholder="Please eneter your password"
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setFieldType((previousFieldType) => {
                    if (previousFieldType === 'password') {
                      return 'text';
                    } else {
                      return 'password';
                    }
                  });
                }}>
                {filedType === 'password' ? <Visibility /> : <VisibilityOff />}
              </InputAdornment>
            ),
          }}
          fullWidth
        />
        <Button type="submit" fullWidth variant="contained" onClick={handleSignIn}>
          Sign In
        </Button>
      </Stack>
    </Stack>
  );
};

export default Signin;
