import React from 'react';
import { Box, Chip, IconButton, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { DataGrid } from '@mui/x-data-grid';
import { DocumentScanner } from '@mui/icons-material';

const UnLoading = () => {
  const [selectedBay, setSelectedBay] = React.useState();
  const [selectedOperation, setSelectedOperatons] = React.useState();

  const selectedWarehouse = useSelector((state) => state.warehouse.selectedWarehouse);
  const unLoadingOperations = useSelector((state) => state.unloadingOperation.unloadingOperations);

  const selectFirstBayOnLoad = () => {
    const firstBay = selectedWarehouse.bays.find((item) => item.status === 'UNLOADING');
    setSelectedBay(firstBay);
  };

  const findOperationForSelectecBay = () => {
    const operation = unLoadingOperations.find((item) => item.bayName === selectedBay?.name);
    setSelectedOperatons(operation);
  };

  React.useEffect(() => findOperationForSelectecBay(), [selectedBay]);
  React.useEffect(() => selectFirstBayOnLoad(), []);

  const isBaySelected = (bay) => selectedBay?.name === bay?.name;

  const columns = [
    {
      field: 'sku',
      headerName: 'SKU',
      width: 200,
    },
    {
      field: 'name',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'orderQuantity',
      headerName: 'Quantity',
      width: 100,
    },
    {
      field: 'deliveredQuantity',
      headerName: 'Delivered',
      width: 100,
    },
    {
      field: 'receivedQuantity',
      headerName: 'Received',
      width: 100,
    },
  ];

  const yetToScanColumns = [
    {
      field: 'sku',
      headerName: 'SKU',
      width: 200,
    },
    {
      field: 'name',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'orderQuantity',
      headerName: 'Quantity',
      width: 100,
    },
    {
      field: 'deliveredQuantity',
      headerName: 'Delivered',
      width: 100,
    },
    {
      field: 'receivedQuantity',
      headerName: 'Received',
      width: 100,
    },
    {
      field: 'actions',
      headerName: 'Scan',
      width: 60,
      renderCell: () => {
        return (
          <Stack direction="row">
            <IconButton title="Scan">
              <DocumentScanner />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack spacing={2} width="100%" height="100%">
      <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center">
        {selectedWarehouse.bays.map((item) => (
          <Stack
            key={item.id}
            px={2}
            py={2}
            bgcolor={isBaySelected(item) ? '#1E3163' : '#171820'}
            onClick={() => setSelectedBay(item)}
            sx={{ cursor: 'pointer' }}
            mr={1}
            mb={1}
            borderRadius={2}
            spacing={1}
            alignItems="center"
            justifyContent="center">
            <Typography fontWeight={700} variant="h5">
              {item.name}
            </Typography>
            <Chip
              size="small"
              label={item.status}
              color={
                item.status === 'LOADING'
                  ? 'success'
                  : item.status === 'UNLOADING'
                  ? 'info'
                  : 'warning'
              }
            />
          </Stack>
        ))}
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography fontSize="1.1rem" fontWeight={700}>
          Showing Result For
        </Typography>
        {selectedBay ? (
          <Chip label={selectedBay.name} size="small" />
        ) : (
          <Chip label="Select a bay to view details" size="small" />
        )}
      </Stack>
      {selectedOperation ? (
        <Stack spacing={2}>
          <Stack direction="row" flexWrap="wrap">
            <Stack px={2} bgcolor="#ECB365" py={2} mr={1} mb={1} color="#171820" borderRadius={2}>
              <Typography variant="h5" fontWeight={700}>
                {selectedOperation?.orderType}-{selectedOperation.orderNumber}
              </Typography>
              <Typography>{selectedOperation.bayName}</Typography>
            </Stack>
            <Stack px={2} bgcolor="#1E3163" py={2} mr={1} mb={1} borderRadius={2}>
              <Typography variant="h6" fontWeight={700}>
                Unloading From - {selectedOperation.truckNumber}
              </Typography>
              <Typography>Should Complete At {dayjs().format('hh:mm A DD MMM, YYYY')}</Typography>
            </Stack>
            <Stack px={2} bgcolor="#1E3163" py={2} mr={1} mb={1} borderRadius={2}>
              <Typography>
                Unloading from &ldquo;{selectedOperation.source}&ldquo; at &ldquo;
                {selectedOperation.bayName}&ldquo;
              </Typography>
              <Typography>Came From: {selectedOperation.destination}</Typography>
            </Stack>
          </Stack>
          <Typography fontSize="1.1rem" fontWeight={700}>
            Scanned Items
          </Typography>
          <Box width="100%" height={300}>
            <DataGrid
              columns={columns}
              rows={selectedOperation?.products?.filter((item) => item.isScanned) || []}
              getRowId={(row) => row.sku}
            />
          </Box>
          <Typography fontSize="1.1rem" fontWeight={700}>
            Yet To Scan
          </Typography>
          <Box width="100%" height={300}>
            <DataGrid
              columns={yetToScanColumns}
              rows={selectedOperation?.products?.filter((item) => !item.isScanned) || []}
              getRowId={(row) => row.sku}
            />
          </Box>
        </Stack>
      ) : (
        <Stack width="100%" height="50vh" spacing={2} alignItems="center" justifyContent="center">
          <Box component="img" src="/assets/images/box.png" height={150} />
          <Typography variant="h3" fontWeight={600}>
            No Operation
          </Typography>
          <Typography fontSize="1.1rem" letterSpacing={1}>
            Unloading Bay <strong>&ldquo;{selectedBay?.name}&ldquo;</strong> is currently sitting
            idle
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default UnLoading;
